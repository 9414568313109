import { createApp } from "vue"
import Entry from "./Entry"
import { initRouter } from "./router"
import { store, key } from "./store"
import { Culture, UserTypes } from "@/enums"
import systemHelper from "@/helpers/systemHelper"
import lanHelper from "@/helpers/lanHelper"
import "ant-design-vue/dist/antd.css"
import pageTree from "@/portal/static/pageTree"
import Spin from "./themes/components/Spin/Spin"



;(async () => {
	const app = createApp(Entry).use(store, key)
	
	const spin = createApp(Spin)
	spin.mount("#app")
	
	// await store.dispatch("GET_USER")

	window.globalConfig = Object.assign({}, window.globalConfig, {
		userType: UserTypes.ae,
		culture: localStorage.getItem("locale") as Culture  || Culture.en
	})
	await lanHelper.init(window.globalConfig.culture)


	systemHelper.styleSetting({
		main: "22, 93, 255",
	})
	const { containers } = await systemHelper.getPages(window.globalConfig.userType)
	const availablePaths = systemHelper.pathFilter(pageTree, window.globalConfig.userType)
	const routes = systemHelper.getRoute(availablePaths, containers)

	const router = initRouter(routes)

	app.use(router)

	store.dispatch("INITALIZE", {
		pagePaths: availablePaths,
	})
	
	spin.unmount()

	app.mount("#app")
})()


