import enums from "@/enums"

const routeTree = [
	{
		page: "PartnerLayout",
		path: "/",
		userType: enums.UserTypes.partner,
		children: [
			{
				lanItem: "Home",
				page: "PartnerHome",
				path: "/",
				icon: "icon-jiaoyitongji",
			},
			{
				lanItem: "SalesAndMarketing",
				icon: "icon-tongjixinxi",
				children: [
					{
						lanItem: "MarketingMaterials",
						page: "PartnerMarketingMaterials",
						path: "/marketingmaterials",
						tableLayout: "materials",
					},
					{
						lanItem: "Agent",
						page: "PartnerAgent",
						path: "/agent",
						tableLayout: "agent",
					},
				],
			},
			{
				lanItem: "Training",
				icon: "icon-miaozhunicon-miaozhunicon-miaozhun",
				page: "PartnerTraining",
				path: "/training",
				tableLayout: "training",
			}
		],
	},
	{
		page: "AdminLayout",
		path: "/",
		userType: enums.UserTypes.admin,
		children: [
			{
				lanItem: "DashBoard",
				page: "AdminDashBoard",
				path: "/",
				icon: "icon-jiaoyitongji",
			},
			{
				lanItem: "Partner",
				page: "AdminPartner",
				path: "/partner",
				icon: "icon-2Nxiaodihezuo",
				tableLayout: "partner",
			},
			{
				lanItem: "Content",
				icon: "icon-a-wenjianshuben",
				children: [
					{
						lanItem: "Home",
						page: "AdminHome",
						path: "/home",
					},
					{
						lanItem: "SalesMarketing",
						children: [
							{
								lanItem: "MarketingMaterials",
								page: "AdminMaterials",
								path: "/materials",
								tableLayout: "materials",
							},
							{
								lanItem: "SalesInformation",
								page: "AdminSalesInfo",
								path: "/salesinfo",
								tableLayout: "salesInfo",
							},
							{
								lanItem: "Training",
								page: "AdminTraining",
								path: "/training",
								tableLayout: "training",
							},
							{
								lanItem: "Agent",
								page: "AdminAgent",
								path: "/agent",
								tableLayout: "agent",
							},
						],
					},
				],
			},
			{
				lanItem: "Statistics",
				page: "AdminStatistics",
				path: "/statistics",
				tableLayout: "statistics",
				icon: "icon-chakanbaobiao",
			},
		],
	},
	{
		path: "/",
		userType: enums.UserTypes.null,
		children: [
			{
				lanItem: "Login",
				page: "Login",
				path: "/",
			},
			{
				lanItem: "ResetPassword",
				page: "ResetPassword",
				path: "/resetpassword",
			},
		],
	},
	{
		page: "AELayout",
		path: "/",
		userType: enums.UserTypes.ae,
		children: [
			{
				lanItem: "",
				page: "AELogin",
				path: "/"
			},
			{
				lanItem: "",
				page: "AELogin",
				path: "/login"
			},
			{
				lanItem: "",
				page: "AETable",
				path: "/index",
				icon: "icon-jiaoyitongji",
				tableLayout: "ae"
			}
		],
		
	},
]

export default routeTree
