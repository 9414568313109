import { Spin } from "ant-design-vue"
import { defineComponent } from "vue"
import "./Spin.css"

export default defineComponent({
	name: "Spin",
	setup() {
		return () => (
			<div class="spinBox">
				<Spin size="large" />
			</div>
		)
	},
})
